<template>
  <div class="customers">
    <button @click="saveCustomer">Save</button>
    <button v-if="!isOffline" @click="syncCustomers">Sync</button>
      <div v-if="!isOffline" class="online">
        <div v-if="customers.length <= 0">
          Er konden geen gegeven opgehaald worden. Probeer te wisselen tussen pagina's
        </div>
        <ListItem
            v-for="customer in customers.slice().reverse()"
            :key="customer.id"
            :id="customer.id"
            :title="customer.name"
            type="customer"
        />
      </div>
      <div v-if="isOffline" class="offline">
        <div v-if="localCustomers.length > 0" class="list">
          <ListItem
              v-for="localCustomer in localCustomers.slice().reverse()"
              :key="localCustomer.id"
              :id="localCustomer.id"
              :title="localCustomer.name"
              type="customer"
          />
        </div>
        <div v-if="localCustomers.length <= 0">
          We couldn't get the customers to load.
        </div>
      </div>
  </div>
</template>

<script>
import ListItem from "@/components/ListItemComp";
import axios from 'axios';

export default {
  name: "InspectionView",
  components: {
    ListItem,
  },
  data() {
    return {
      customers: [],
      customer: [],
      localCustomers: [],
      database: null,
      isOffline: !navigator.onLine
    };
  },
  props: {
    getDatabase: { type: Function },
  },

  async created() {
    this.database = await this.getDatabase();
    await this.getCustomers();
    await this.saveCustomer();
  },

   async mounted() {
    axios
        .get("https://app-api.nettt.nl/api/customer")
        .then(response => (this.customers = response.data.data))

    window.addEventListener("offline", () => {
      this.isOffline = true;
    });
    window.addEventListener("online", () => {
      this.isOffline = false;
    });
  },



  methods: {
    async saveCustomer() {
      axios
          .put("https://app-api.nettt.nl/api/customer/", this.customer)
          .then((response) => console.log(response))
          .catch((error) => console.log(error))
      return new Promise((resolve, reject) => {
        let transaction = this.database.transaction('customers', 'readwrite');
        transaction.oncomplete = e => {
          resolve();
        }


        this.customers.forEach(customer => {
          transaction.objectStore('customers').put({
            id: customer.id,
            name: customer.name,
            address: customer.address,
            city: customer.city,
            country: customer.country,
        })
      })
      })
    },
    async getCustomers() {
      return new Promise((resolve) => {
        let transaction = this.database.transaction("customers", "readonly");
        let customersRequest = transaction.objectStore("customers").getAll();

        customersRequest.onsuccess = e => {
          this.localCustomers = e.target.result;
        }
      })
    },
     syncCustomers() {
      console.log('dit werkt')
      axios
          .post("https://app-api.nettt.nl/api/customer/", this.localCustomers)
          .then((response) => console.log(response))
          .catch((error) => console.log(error))
    }
  },
};
</script>
